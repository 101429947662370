import { usePagesStore } from '@voix/store/pagesStore'
import { useAdminStore } from '@voix/store/adminStore'
import { $voixNuxtApi } from '@voix/voix-layer/composables/voixNuxtApi'
import { useVoixToast } from '../useVoixToast'

interface PagesArgs {
  filter: string
  orderBy: Array<object | string>
  first: number
  page: number
  language_code: string
}

// Will return a page or a redirect
// Contains .page and .redirect - one will be null

function setPagesInStore(response: any) {
  if (response) {
    // Store the page data for use in the app
    const pagesStore = usePagesStore()
    pagesStore.setPages(response)
  }
}

async function getPages(variables: PagesArgs, languageCode: string) {
  const adminStore = useAdminStore()
  adminStore.isLoading = true

  const filters = JSON.parse(variables.filter)
  filters.language_code = { field: 'language_code', op: '=', value: languageCode, clause: 'where' }
  variables.filter = JSON.stringify(filters)

  if (variables.orderBy && Object.keys(variables.orderBy).length) {
    variables.orderBy = variables.orderBy.map((orderBy) => {
      return `${orderBy.column}:${orderBy.order}`
    })
  }
  else {
    variables.orderBy = ''
  }

  const query = new URLSearchParams(variables).toString()
  const request = await $voixNuxtApi(
    `/api/voix/pages?${query.toString()}`,
  )

  setPagesInStore(request.data.value)

  adminStore.isLoading = false

  return request
}

interface PageCreateArgs {
  site_id: string
  language_code: string
  path: string
  layout: string
  title: string
}

async function createPage(variables: PageCreateArgs) {
  const adminStore = useAdminStore()
  const voixToast = useVoixToast()
  adminStore.isLoading = true

  const request = await $voixNuxtApi(
    `/api/voix/pages`,
    {
      method: 'POST',
      body: variables,
    },
  )

  adminStore.isLoading = false

  if (request.data.value) {
    voixToast.add(`Page Created: ${variables.title}`, 'msg')

    return request
  }
  else {
    throw new Error('Unable to save')
  }
}

interface CopyPageArgs {
  id: string
  site_id: string
  language_code: string
  path: string
  layout: string
  title: string
}

async function copyPage(pageData: CopyPageArgs) {
  const adminStore = useAdminStore()
  adminStore.isLoading = true

  const request = await $voixNuxtApi(
    `/api/voix/page-copies`,
    {
      method: 'POST',
      body: pageData,
    },
  )

  adminStore.isLoading = false

  return request
}

async function translatePage(pageData: CopyPageArgs) {
  const adminStore = useAdminStore()
  adminStore.isLoading = true

  const request = await $voixNuxtApi(
    `/api/voix/page-localizations`,
    {
      method: 'POST',
      body: pageData,
    },
  )

  adminStore.isLoading = false

  return request
}

export { getPages, copyPage, createPage, translatePage }
