import { defineStore } from 'pinia'
import type { PageInterface, PaginatorInfoInterface } from '@voix/types'

interface PagesAdminInterface {
  pages: {
    paginatorInfo: PaginatorInfoInterface
    data: Array<PageInterface>
  }
}

export const usePagesStore = defineStore('pagesStore', {
  state: () =>
    ({
      pages: { paginatorInfo: {}, data: [] },
    } as PagesAdminInterface),

  actions: {
    setPages(pages: {
      paginatorInfo: PaginatorInfoInterface
      data: Array<PageInterface>
    }) {
      this.pages = pages
    },
  },
})
